import { AssetCloudinary, ButtonCS } from '~/types/contentstack';
import { getFirstIndex } from '~/util/arrays';
import { InternalLink, Link } from '~/types/link-menu';
import { useCloudinary } from '~/composables/cloudinary';

type PublicId = string;

export function getAssetPublicId(images: AssetCloudinary[]): PublicId {
  return images?.[0]?.public_id || '';
}

export function getImageAlt(images: AssetCloudinary[]): string | undefined {
  // transform/3d8daa63-801e-4f8f-bbb9-a451ce5baaf3/Jack-Wolfsin-Collab-Ranger-FPCS-004
  const filename = images?.length ? images?.[0]?.public_id : undefined;
  return filename?.split('/').pop()?.split('.')[0]?.replace(/-/g, ' ') || undefined;
}

type ContentReference = {
  edges: {
    node: any;
  }[];
};

export function extractComponent(connection: ContentReference) {
  const firstItem = getFirstIndex(connection?.edges);
  return firstItem?.node;
}

export function extractItems(connection: any): any {
  return connection?.edges?.map((item: any) => {
    return item?.node;
  });
}

export function menuEdge(nav: any, index = 0) {
  const indexPointer = nav?.edges?.length < index ? nav?.edges?.length : index;
  return nav?.edges?.length ? nav.edges[indexPointer]?.node?.link || [] : [];
}

export function extractInternalLink(item: InternalLink) {
  const url = item?.node?.url;
  const postType = (item as any)?.node?.post_type?.post_type;
  if (postType) {
    return `/community/${postType}/p${url}`;
  }
  return url;
}

/**
 * Returns mapped nav structure for Component Use
 * @param item Possibly nested link item
 * @param linkTopLevel whether or not the link is a top level link and should be clickable
 * @returns mapped nav link item
 */
export function mapMenuItem(item: Link | ButtonCS, linkTopLevel = true) {
  const localePath = useLocalePath();
  const cloudinary = useCloudinary();
  const extLink = item?.external_link;
  const intLink = item?.internal_linkConnection;
  const hashLink = item?.hash_link;
  const title = (item as Link)?.title || extLink?.title || hashLink?.link_text || '';
  const shortDescription = (item as any)?.short_description || null;
  const refString = title.charAt(0).toLowerCase() + title.replaceAll(' ', '-').slice(1);
  const hasDropdownContent = !!(item as Link)?.link?.length;
  const hasBadge = (item as Link)?.badge?.text !== '' && !!(item as Link)?.badge?.theme;
  const boldItem = title?.toLowerCase().match(/^all /) ? title?.toLowerCase().match(/^all /) : null;
  let toLinker = intLink?.edges?.[0]?.node?.url ? localePath(intLink.edges[0].node.url) : null;
  let hrefLinker = extLink?.href && !toLinker ? extLink.href : null;
  let isHashLink = false;
  if (hashLink?.link_text && hashLink?.element_id) {
    toLinker = null;
    isHashLink = true;
    hrefLinker = `#${hashLink.element_id}`;
  }
  const mobPubId = (item as Link)?.mobile_image?.[0]?.public_id;
  const contentPubId = (item as Link)?.content_image?.[0]?.public_id;
  const mobImgUrl = mobPubId ? cloudinary.generateImageUrl(mobPubId, { width: 48, height: 48 }) : null;
  const contentImgUrl = contentPubId ? cloudinary.generateImageUrl(contentPubId, { width: 350, height: 180 }) : null;
  const productHighlightSection = (item as Link)?.product_highlight_section;
  if ((item as Link)?.is_system_link) {
    hrefLinker = null;
    toLinker = localePath(extLink.href as string);
  }
  return {
    title,
    href: linkTopLevel && hrefLinker ? hrefLinker : undefined,
    to: linkTopLevel && toLinker ? toLinker : undefined,
    hasBadge,
    boldItem,
    shortDescription,
    refString,
    hasDropdownContent,
    badge: (item as Link)?.badge,
    hasIcon: false,
    isHashLink,
    link: (item as Link)?.link,
    mobile_image: mobImgUrl,
    contentImg: {
      url: contentImgUrl,
      header: (item as Link)?.image_header,
      description: (item as Link)?.image_description,
    },
    featuredNav: {
      header: (item as Link)?.featured_nav?.header,
      links: (item as Link)?.featured_nav?.pageConnection?.edges?.map((item: any) => {
        return {
          title: item?.node?.title,
          display_title: item?.node?.display_title,
          url: localePath(item?.node?.url),
        };
      }),
    },
    productHighlightSection,
    svg: (item as Link)?.svg,
  };
}
