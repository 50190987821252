// composables/useCloudinary.ts

import { Cloudinary } from '@cloudinary/url-gen';
import { fill, fit, scale, crop, pad } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { QualityTypes, CropMode } from '@cloudinary/url-gen/types/types';
import { ICompassGravity } from '@cloudinary/transformation-builder-sdk/qualifiers/gravity/compassGravity/CompassGravity';
import { useConnectionStore } from '~/store/connection';

export type Transform =
  | 'fill'
  | 'fit'
  | 'extend'
  | 'scale'
  | 'crop'
  | 'rotate'
  | 'flip'
  | 'mirror'
  | 'border'
  | 'background'
  | 'pad';

export type ImageOptions = {
  width?: number | 'auto';
  height?: number | 'auto';
  crop?: CropMode;
  transform?: Transform;
  dpr?: number;
  quality?: QualityTypes | number;
  gravity?: ICompassGravity;
};

export type VideoOptions = {
  width?: number;
  height?: number;
  transform?: Transform;
  thumbnail?: boolean;
  quality?: QualityTypes | number;
  gravity?: ICompassGravity;
};

export type ProgressiveImageUrl = {
  quality: number | QualityTypes;
  url: string;
};

export type CldGravity = ICompassGravity;

export const useCloudinary = (enableLogging = false) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'solostove',
    },
    url: {
      secure: true,
      secureDistribution: 'content.solostove.com',
      privateCdn: true,
    },
  });

  const qualityArray: (QualityTypes | number)[] = [1, 'auto', 'auto:best'];
  const generateImageUrl = (publicId: string, options?: ImageOptions) => {
    if (enableLogging) console.time('generateImageUrl');
    const image = generateCldImage(publicId, options);
    const connectionStore = useConnectionStore();
    const isFastConnection = connectionStore.fastConnection;
    const quality = options?.quality || 'auto';
    image.delivery(dpr(isFastConnection && options?.dpr ? options.dpr : 'auto'));
    image.quality(quality);
    const url = image.toURL();
    if (enableLogging) console.timeEnd('generateImageUrl');
    return url;
  };

  const generateProgressiveImageUrls = (
    publicId: string,
    options?: ImageOptions,
    qualityArrayOverride?: typeof qualityArray
  ): ProgressiveImageUrl[] => {
    if (enableLogging) console.time('generateProgressiveImageUrls');
    const connectionStore = useConnectionStore();
    const isFastConnection = connectionStore.fastConnection;
    const currQualityArray = qualityArrayOverride || qualityArray;

    const imagesArray = currQualityArray.map((qualityLevel, i) => ({
      quality: qualityLevel,
      url: generateCldImage(publicId, options)
        .quality(qualityLevel)
        .delivery(dpr(isFastConnection && options?.dpr ? options.dpr : i === 0 ? 1 : 'auto'))
        .toURL(),
    }));

    if (enableLogging) console.timeEnd('generateProgressiveImageUrls');
    return imagesArray;
  };

  const generateCldImage = (publicId: string, options?: ImageOptions) => {
    if (enableLogging) console.time('generateCldImage');
    const image = cld.image(publicId);

    if (options) {
      const resizeAction = transformToResizeMode(options?.transform || 'fill');
      const resizeWidth = typeof options.width === 'number' ? Math.round(options.width) : options.width;
      const resizeHeight = typeof options.height === 'number' ? Math.round(options.height) : options.height;
      if (resizeWidth) resizeAction.width(resizeWidth);
      if (resizeHeight) resizeAction.height(resizeHeight);
      // @ts-ignore-next-line -- .gravity is not in type
      resizeAction.gravity(options?.gravity || autoGravity());
      if (resizeWidth || resizeHeight) image.resize(resizeAction);
    }

    image.format('auto');
    if (enableLogging) console.timeEnd('generateCldImage');
    return image;
  };

  const generateCldVideo = (publicId: string, options?: VideoOptions) => {
    if (enableLogging) console.time('generateCldVideo');
    const video = cld.video(publicId);
    const resizeAction = transformToResizeMode(options?.transform || 'fill');
    if (options?.width) resizeAction.width(Math.round(options.width));
    if (options?.height) resizeAction.height(Math.round(options.height));
    // @ts-ignore-next-line -- .gravity is not in type
    resizeAction.gravity(options?.gravity || autoGravity());
    video.resize(resizeAction);

    if (enableLogging) console.timeEnd('generateCldVideo');
    return video;
  };

  const generateVideoUrl = (publicId: string, options?: VideoOptions) => {
    if (enableLogging) console.time('generateVideoUrl');
    const video = generateCldVideo(publicId, options);
    const connectionStore = useConnectionStore();
    const isFastConnection = connectionStore.fastConnection;
    const quality = options?.quality || 'auto';
    let isThumbnail = false;
    const dprScale = isFastConnection ? window.devicePixelRatio : 1;
    if (options?.thumbnail) isThumbnail = options.thumbnail;
    video.delivery(dpr(dprScale));
    if (!isThumbnail) {
      video.quality(quality).format('auto:video');
    } else {
      video.quality(quality).format('auto');
    }
    const url = video.toURL();
    if (enableLogging) console.timeEnd('generateVideoUrl');
    return url;
  };

  const transformToResizeMode = (transform: Transform) => {
    switch (transform) {
      case 'fill':
        return fill();
      case 'fit':
        return fit();
      case 'scale':
        return scale();
      case 'crop':
        return crop();
      case 'pad':
        return pad();
      default:
        return fill(); // Default or fallback to 'fill'
    }
  };

  return {
    generateImageUrl,
    generateProgressiveImageUrls,
    generateVideoUrl,
    generateCldImage,
    generateCldVideo,
    qualityArray,
  };
};
