export interface DeviceState {
  device?: ReturnType<typeof useDevice>;
}

export const useDeviceStore = defineStore('device', {
  state: (): DeviceState => ({
    device: undefined,
  }),

  actions: {
    setDevice(device: DeviceState['device']) {
      this.device = device;
    },
  },

  getters: {
    isDesktop(state) {
      return state?.device?.isDesktop === true;
    },
    isTablet(state) {
      return (
        state?.device?.isTablet === true ||
        (state?.device?.isDesktopOrTablet === true &&
          // @ts-expect-error
          this.isDesktop === true &&
          state?.device?.isMobileOrTablet === false) ||
        false
      );
    },
    isMobileOrTablet() {
      // @ts-expect-error
      return ((this.isTablet === true || this.isMobile === true) && this.isDesktop === false) || false;
    },
    isMobile(state) {
      return state?.device?.isMobile === true;
    },
  },
});
