interface IndexState {
  brandSettings: any;
  algoliaSettings: any;
  svgs: any[];
  yotpoSettings: {
    appKey?: string;
    starsInstanceId?: string;
    reviewsInstanceId?: string;
  };
}

export const useRootStore = defineStore('index', {
  state: (): IndexState => ({
    brandSettings: {} as any,
    algoliaSettings: {} as any,
    svgs: [] as any[],
    yotpoSettings: {},
  }),

  getters: {
    freeShippingThreshold: (state) => state.brandSettings?.free_shipping_threshold,
    freeShippingProducts: (state) =>
      state.brandSettings?.free_shipping_productsConnection?.edges?.map((item: any) => item?.node),
    siteUnderMaintenance: (state) => state.brandSettings?.site_maintenance?.maintenance_active,
    yotpoAppKey: (state) => state.yotpoSettings?.appKey,
    yotpoStarsInstanceId: (state) => state.yotpoSettings?.starsInstanceId,
    yotpoReviewsInstanceId: (state) => state.yotpoSettings?.reviewsInstanceId,
  },

  actions: {
    setBrandSettings(brandSettings: any) {
      this.brandSettings = brandSettings;
    },
    setAlgoliaSettings(algoliaSettings: any) {
      this.algoliaSettings = algoliaSettings;
    },
    setYotpoSettings(yotpoSettings: IndexState['yotpoSettings']) {
      this.yotpoSettings = yotpoSettings;
    },
    setYotpoAppKey(appKey: string) {
      this.yotpoSettings.appKey = appKey;
    },
  },
});
