// WARNING: This file is used in /api - outside of Nuxt
// import { type fetch as cfFetch, type Response } from '@cloudflare/workers-types';
import { getQuery, type H3Event } from 'h3';
import { RuntimeConfig } from 'nuxt/schema';
import { NApiCTSContext, NApiContext } from '../api/contentstack/[...contentstack]';
import { headerQuery } from './graphql/contentstack/header';
import { footerQuery } from './graphql/contentstack/footer';
import { homeQuery } from './graphql/contentstack/home';
import { brandSettingsQuery } from './graphql/contentstack/brand-settings';
import { algoliaSettingsQuery } from './graphql/contentstack/algolia-settings';
import { featureFlagQuery } from './graphql/contentstack/feature-flag';
import { regionsLaunchedQuery } from './graphql/contentstack/regions-launched';
import { pageQuery } from './graphql/contentstack/page';
import {
  postQuery,
  postsByCatUrlPostType,
  allBlogs,
  getCategory,
  communityLP,
  communityLPSections,
} from './graphql/contentstack/community';
import {
  productQuery,
  productsByBCIdQuery,
  filterByBCIdQuery,
  productQuerySections,
  productIdsQuery,
} from './graphql/contentstack/product';
import { clpQuery } from './graphql/contentstack/clp';
import { plpQuery } from './graphql/contentstack/plp';
import { spQuery, spQuerySections } from './graphql/contentstack/splashPage';
import { hlpQuery } from './graphql/contentstack/hlp';
import { compactGraphQLQuery } from './compress';
import { gwpQuery } from './graphql/contentstack/product-gwp';
import { $fetchRaw, cloudflareContext, soloCacheHeaderName } from './cloudflare';

export type QueryObject = ReturnType<typeof getQuery>;
// @ts-expect-error
export interface CtsQueryParams extends QueryObject {
  locale?: string;
  regionCode?: string;
  ids_in?: string[];
}

export type pageQuery = {
  pageQuery: typeof pageQuery;
  productQuery: typeof productQuery;
  productQuerySections: typeof productQuerySections;
  productIdsQuery: typeof productIdsQuery;
  clpQuery: typeof clpQuery;
  plpQuery: typeof plpQuery;
  postQuery: typeof postQuery;
  spQuery: typeof spQuery;
  spQuerySections: typeof spQuerySections;
  hlpQuery: typeof hlpQuery;
};

export type CtsEnvVars = {
  cdaToken: string;
  ctsApiKey: string;
  environment: string;
  ctsApiBranch: string;
  cacheMaxAge: string;
  cacheStale: string;
  cacheExtraValues: string;
  cacheClearSiteData: string;
  cacheTags: string;
  devCacheBust: string;
  loggingEnabled: boolean;
};

export enum QueryTypes {
  page = 'pageQuery',
  product = 'productQuery',
  productSections = 'productQuerySections',
  productIds = 'productIdsQuery',
  postQuery = 'postQuery',
  clp = 'clpQuery',
  plp = 'plpQuery',
  sp = 'spQuery',
  sps = 'spQuerySections',
  hlp = 'hlpQuery',
}

export const createNewEnv = (config: RuntimeConfig): CtsEnvVars => {
  // @ts-expect-error
  return {
    environment: config.public.environment,
    ctsApiKey: config.ctsApiKey,
    cdaToken: config.cdaToken,
    ctsApiBranch: config.public.ctsApiBranch,
    cacheMaxAge: config.public.cacheMaxAge,
    cacheStale: config.public.cacheStale,
    cacheExtraValues: config.public.cacheExtraValues,
    cacheClearSiteData: config.public.cacheClearSiteData,
    cacheTags: config.public.cacheTags,
    devCacheBust: config.public.devCacheBust,
  };
};

export default class ContentstackApi {
  pageQuery: pageQuery;
  env: CtsEnvVars;

  constructor(env: CtsEnvVars) {
    this.pageQuery = {
      pageQuery,
      productQuery,
      productQuerySections,
      productIdsQuery,
      clpQuery,
      plpQuery,
      postQuery,
      spQuery,
      spQuerySections,
      hlpQuery,
    };
    this.env = env;
  }

  public getBrandSettings(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(brandSettingsQuery(ctx.query), ctx);
  }

  public getAlgoliaSettings(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(algoliaSettingsQuery('Global Algolia Settings', ctx.query), ctx);
  }

  public getSearchPageSettings(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(algoliaSettingsQuery('Search Page Algolia', ctx.query), ctx);
  }

  public getFeatureFlag(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(featureFlagQuery(ctx.query), ctx);
  }

  public getRegionsLaunched(env: CtsEnvVars, ctx: NApiContext): Promise<unknown> {
    return this.fetchQuery(regionsLaunchedQuery, ctx);
  }

  public getHeader(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(headerQuery(ctx.query), ctx);
  }

  public getFooter(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(footerQuery(ctx.query), ctx);
  }

  public getHome(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(homeQuery(ctx.query), ctx);
  }

  public getCommunityLP(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(communityLP(ctx.query), ctx);
  }

  public getCommunityLPSections(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(communityLPSections(ctx.query), ctx);
  }

  public getAllBlogs(
    env: CtsEnvVars,
    ctx: NApiCTSContext<
      {
        parsedLimit: number;
        postType: string;
      },
      {
        query: CtsQueryParams;
      }
    >
  ): Promise<unknown> {
    const { extraData } = ctx;
    const { parsedLimit, postType } = extraData;
    return this.fetchQuery(allBlogs(parsedLimit, postType, ctx.query), ctx);
  }

  public postsByCatUrlPostType(
    env: CtsEnvVars,
    ctx: NApiCTSContext<
      {
        postType: string;
        categoryUrl: string;
      },
      {
        query: CtsQueryParams;
      }
    >
  ): Promise<unknown> {
    const { extraData } = ctx;
    const { categoryUrl, postType } = extraData;
    return this.fetchQuery(postsByCatUrlPostType(categoryUrl, postType, ctx.query), ctx);
  }

  public getCommunityCategory(
    env: CtsEnvVars,
    ctx: NApiCTSContext<
      {
        url: string;
      },
      {
        query: CtsQueryParams;
      }
    >
  ): Promise<unknown> {
    const { extraData } = ctx;
    const { url } = extraData;
    return this.fetchQuery(getCategory(url, ctx.query), ctx);
  }

  public getPage(
    env: CtsEnvVars,
    ctx: NApiCTSContext<
      {
        url: string;
        query: keyof pageQuery;
      },
      {
        query: CtsQueryParams;
      }
    >
  ): Promise<unknown> {
    try {
      const { extraData } = ctx;
      const { url, query } = extraData;
      const fn = this.pageQuery[query];
      return this.fetchQuery(fn(url as never, ctx.query), ctx);
    } catch (error) {
      return Promise.resolve(error);
    }
  }

  public getProductsIn(
    env: CtsEnvVars,
    ctx: NApiCTSContext<
      {
        ids: string[]; // was number[], but string[] is better for the methods used
      },
      {
        query: CtsQueryParams;
      }
    >
  ): Promise<unknown> {
    const { extraData } = ctx;
    const { ids } = extraData || {};
    return this.fetchQuery(productsByBCIdQuery(ids, ctx.query), ctx);
  }

  public getProductIdFilter(
    env: CtsEnvVars,
    ctx: NApiCTSContext<
      {
        bcId: string;
      },
      {
        query: CtsQueryParams;
      }
    >
  ): Promise<unknown> {
    const { extraData } = ctx;
    const { bcId } = extraData;
    return this.fetchQuery(filterByBCIdQuery(bcId, ctx.query), ctx);
  }

  public getProductGwp(env: CtsEnvVars, ctx: NApiContext<{ query: CtsQueryParams }>): Promise<unknown> {
    return this.fetchQuery(gwpQuery(ctx.query), ctx);
  }

  private async fetchQuery(query: string, ctx: NApiContext): Promise<unknown> {
    try {
      const fetchHeaders = new Headers();
      // fetchHeaders.append('api_key', this.env.ctsApiKey);
      fetchHeaders.append('access_token', this.env.cdaToken);
      fetchHeaders.append('Content-Type', 'application/json');
      if (this.env.ctsApiBranch) fetchHeaders.append('branch', this.env.ctsApiBranch);
      // fetchHeaders.append('branch', this.env.ctsApiBranch);
      if (this.env.loggingEnabled)
        console.info(`:: Begin CTS fetchQuery() for ${this.env.ctsApiKey} ${this.env.environment} ::`);
      // if (this.env?.environment && this.env.environment !== 'production') fetchHeaders.append('branch', this.env.environment);

      // console.log('BEFORE COMPRESSION', new Blob([query]).size)
      const compactedQuery = compactGraphQLQuery(query);
      // console.log('AFTER COMPRESSION', new Blob([compactedQuery]).size)
      const graphql = JSON.stringify({
        query: compactedQuery,
        variables: {},
      });

      let cfCtx = ctx.event ? cloudflareContext(ctx.event) : ctx.cfCtx;
      if (!cfCtx) cfCtx = {};
      if (!cfCtx.env) cfCtx.env = this.env;

      // console.log('fetching', graphql);
      if (this.env.loggingEnabled && query.includes('all_home')) {
        console.log('fetching', graphql);
      }

      // Currently used in /api - be sure changes are safe to implement
      const res = await $fetchRaw(
        `https://graphql.contentstack.com/stacks/${this.env.ctsApiKey}?environment=${this.env.environment}`,
        cfCtx,
        {
          method: 'POST',
          headers: fetchHeaders,
          // strip extra char
          body: graphql,
          ignoreResponseError: true,
          // redirect: 'follow' as string | undefined,
        }
      );
      // if (query.includes('all_home')) {
      //   console.log('fetched', res);
      //   (res._data.errors || []).forEach((err) => {
      //     console.error(err);
      //   });
      // }
      // if (this.env.loggingEnabled) console.info(fetchHeaders);
      // if (this.env.loggingEnabled) console.info(res.headers);
      if ((res?.status || 400) > 399) {
        // eslint-disable-next-line no-console
        console.error(JSON.stringify(res._data.error, null, 2));
        // TODO: find out what call is failing, and include in error message
        // const message = res?.statusText || 'Bad Request';
        // console.log(res);
        // console.log(`Message: ${message}`);
        // console.log(`Status: ${JSON.stringify(res)}`);
        // throw new Error(message); // TODO: extract other meaningful data from parsed JSON in error response
      }
      const soloCacheHeader = res?.headers?.get(soloCacheHeaderName);
      // const cacheControlHeader = res.headers.get('cache-control');
      // console.log({soloCacheHeader, cacheControlHeader});
      if (this.env.loggingEnabled) fetchHeaders.forEach((v, k) => console.info(`Fetch Header: ${k}=${v}`));

      if (ctx.event)
        // for api-layer compat
        appendResponseHeader(ctx.event, soloCacheHeaderName, soloCacheHeader);

      if (this.env.loggingEnabled)
        // eslint-disable-next-line no-console
        console.info(`:: END CTS fetchQuery() for ${this.env.ctsApiKey} ${this.env.environment} ::`);
      // eslint-disable-next-line no-console
      if (this.env.loggingEnabled) console.info('');

      return res?._data || {};
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      // TODO: make everything that calls what abstracts this message have proper
      // error handling, and change Promise.resolve to Promise.reject
      // console.error(error);
      return Promise.resolve(error);
    }
  }
}

/**
 * Collect tags for the environment and query parameters.
 */
function getBaseTags(env: CtsEnvVars, query: CtsQueryParams) {
  const tags = [`env:${env.environment}`, `${env.environment}-solo-caches`, `contentstack:${env.ctsApiKey}`];

  if (query.locale) {
    tags.push(`locale:${query.locale}`);
  }

  return tags;
}

/**
 * Extract headers to a plain object for debugging.
 */
function extractHeaders(response: Response) {
  const result: Record<string, string> = {};

  // API-level types causes this to fail `tsc`.
  // TODO: The API should not be checking these types,
  //       but we're stuck with it for now.
  // @ts-ignore
  for (const [key, value] of response.headers.entries()) {
    result[key] = value;
  }

  return result;
}

/**
 * Make a fetch request to the Contentstack API with
 * relevant Cloudflare cache parameters.
 */
async function fetchFromContentstack(env: CtsEnvVars, event: H3Event, id: 'footer' | 'header' | 'home') {
  const query: CtsQueryParams = getQuery(event);
  const tags: string[] = [];
  let graphql: string;

  if (id === 'footer') {
    graphql = footerQuery(query);
    tags.push('nuxt:api:contentstack:footer');
  } else if (id === 'header') {
    graphql = headerQuery(query);
    tags.push('nuxt:api:contentstack:header');
  } else if (id === 'home') {
    graphql = homeQuery(query);
    tags.push('nuxt:api:contentstack:home');
  } else {
    throw new Error(`invalid contentstack query id "${id}"`);
  }

  const headers = new Headers();

  headers.set('access_token', env.cdaToken);
  headers.set('Content-Type', 'application/json');

  if (env.ctsApiBranch) {
    headers.set('branch', env.ctsApiBranch);
  }

  const body = JSON.stringify({
    query: compactGraphQLQuery(graphql),
    variables: {},
  });

  const url = new URL('https://graphql.contentstack.com');

  url.pathname = `/stacks/${env.ctsApiKey}`;
  url.searchParams.set('environment', env.environment);

  const cacheTags = [...new Set([...getBaseTags(env, query), ...tags])];

  const response = await fetch(url.toString(), {
    body,
    cf: {
      cacheTags,
    },
    headers,
    method: 'POST',
  });

  if (env.environment !== 'production') {
    appendResponseHeader(event, 'X-Solo-Debug', JSON.stringify(extractHeaders(response)));
  }

  appendResponseHeader(event, 'X-Solo-Cache-Status', response.headers.get('CF-Cache-Status') ?? 'UNAVAILABLE');

  appendResponseHeader(event, 'X-Solo-Cache-Tags', cacheTags.join(' '));

  return await response.json();
}

export { fetchFromContentstack };
