import revive_payload_client_4sVQNw7RlN from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import templates_pwa_client_67cb3ba2_7X4jqieZpd from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/.nuxt/templates.pwa.client.67cb3ba2.ts";
import i18n_yfWm7jX06p from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_BqLkNUef68 from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_bus_client_iMhR5ModCw from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/plugins/1.bus.client.ts";
import device_PxhEqcDcOg from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/plugins/device.ts";
import gtm_regions_y0P0qLoBVm from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/plugins/gtm-regions.ts";
import klarna_client_QhBInbOfyc from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/plugins/klarna.client.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/plugins/sentry.client.ts";
import plugin_client_e0vcELtzz1 from "/home/runner/work/solo-brands-scaffolding/solo-brands-scaffolding/webapp/modules/meta/runtime/plugin.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  templates_pwa_client_67cb3ba2_7X4jqieZpd,
  i18n_yfWm7jX06p,
  plugin_BqLkNUef68,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  _1_bus_client_iMhR5ModCw,
  device_PxhEqcDcOg,
  gtm_regions_y0P0qLoBVm,
  klarna_client_QhBInbOfyc,
  sentry_client_shVUlIjFLk,
  plugin_client_e0vcELtzz1
]