<template>
  <div v-if="componentProps && componentName" :class="componentClasses">
    <ScrollableContainer
      v-if="hasMultipleItems"
      :id="id"
      :is-product-highlight="isProductHighlight"
      :few-items="componentProps.length === 2"
      :is-component-section="true"
    >
      <template v-for="(item, index) in componentProps" :key="`${index}_comp_dynamic`">
        <component
          :is="componentName"
          v-bind="item"
          :index="index"
          :locale="{
            currencySymbol: localeStore.currencySymbol,
            currencyCode: localeStore.currencyCode,
            langCode: localeStore.langCode,
          }"
        />
      </template>
    </ScrollableContainer>
    <template v-else>
      <component
        :is="componentName"
        v-bind="componentProps"
        :locale="{
          currencySymbol: localeStore.currencySymbol,
          currencyCode: localeStore.currencyCode,
          langCode: localeStore.langCode,
        }"
      />
    </template>

    <div v-if="displayComponentButton" class="button-container flex-inline cntr">
      <CustomButton :to="localePath(buttonLink)">
        {{ sectionEntry.primary_button_text }}
      </CustomButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { has, get, isArray, kebabCase } from 'lodash-es';
import { getFirstIndex } from '~/util/arrays';
import { generateIdNoDate } from '~/util/generateId';
import { triggerEventWhenSectionVisible } from '~/util/eventHandler';
import { GtmEventType, mapProductCardsItemsToGtmEvent } from '~/util/analytics';
import { extractComponent } from '~/util/contentstack/csHelpers';
import { useConnectionStore } from '~/store/connection';
import { useLocaleStore } from '~/store/locale';
import { useRootStore } from '~/store';
import { useDeviceStore } from '~/store/device';

const deviceStore = useDeviceStore();
const rootStore = useRootStore();
const localePath = useLocalePath();
const localeStore = useLocaleStore();
const connectionStore = useConnectionStore();
const config = useRuntimeConfig();
const gtm = useGtm();
const yotpoKey = rootStore.yotpoAppKey;

// START - 1-off special cases
const ProductCard = defineAsyncComponent(() => import('~/components/ProductCard.vue'));
// END - 1-off special cases

// separated by 5 for readability - 1:1 with above
const Banner = defineAsyncComponent(() => import('~/components/Banner.vue'));
const InfoCard = defineAsyncComponent(() => import('~/components/InfoCard.vue'));
const ContentBlock = defineAsyncComponent(() => import('~/components/ContentBlock.vue'));
const Faq = defineAsyncComponent(() => import('~/components/Faq.vue'));
const VideoComp = defineAsyncComponent(() => import('~/components/Video.vue'));
const TabbedImageAndProducts = defineAsyncComponent(() => import('~/components/TabbedImageAndProducts.vue'));

const ImageGallery = defineAsyncComponent(() => import('~/components/ImageGallery.vue'));
const AlternatingImages = defineAsyncComponent(() => import('~/components/AlternatingImages.vue'));
const CategoryCards = defineAsyncComponent(() => import('~/components/CategoryCards.vue'));
const DYCampaign = defineAsyncComponent(() => import('~/components/DYCampaign.vue'));
const YotpoGallery = defineAsyncComponent(() => import('~/components/YotpoGallery.vue'));

const JotformEmbed = defineAsyncComponent(() => import('~/components/JotformEmbed.vue'));
const TripleBanner = defineAsyncComponent(() => import('~/components/TripleBanner.vue'));
const Snackbar = defineAsyncComponent(() => import('~/components/Snackbar.vue'));
const TopBanner = defineAsyncComponent(() => import('~/components/SliderTopBanner.vue'));
const DYRecWidget = defineAsyncComponent(() => import('~/components/DYRecWidget.vue'));
const CTSRecWidget = defineAsyncComponent(() => import('~/components/CTSRecWidget.vue'));

const PostCarousel = defineAsyncComponent(() => import('~/components/PostCarousel.vue'));
const FirestartersBanner = defineAsyncComponent(() => import('~/components/FirestartersBanner.vue'));
const InfoBoxes = defineAsyncComponent(() => import('~/components/InfoBoxes.vue'));
const QuoteCarousel = defineAsyncComponent(() => import('~/components/QuoteCarousel.vue'));
const ValueProp = defineAsyncComponent(() => import('~/components/ValueProp.vue'));

const ComparisonTable = defineAsyncComponent(() => import('~/components/ComparisonTable.vue'));
const ScrollToNav = defineAsyncComponent(() => import('~/components/ScrollToNav.vue'));
const GleamRaffle = defineAsyncComponent(() => import('~/components/GleamRaffle.vue'));
const GuidedSellingCarousel = defineAsyncComponent(() => import('~/components/GuidedSellingCarousel.vue'));

const HlpHeroModule = defineAsyncComponent(() => import('~/components/HlpHeroModule.vue'));
const HlpImageModule = defineAsyncComponent(() => import('~/components/HlpImageModule.vue'));
const HlpVideoModule = defineAsyncComponent(() => import('~/components/HlpVideoModule.vue'));
const HlpCategoryModule = defineAsyncComponent(() => import('~/components/HlpCategoryModule.vue'));
const HlpProductAndCategoryGridModule = defineAsyncComponent(
  () => import('~/components/HlpProductAndCategoryGridModule.vue')
);
const HlpTickerModule = defineAsyncComponent(() => import('~/components/HlpTickerModule.vue'));

const compsMapper: any = {
  // if you add a new component here, add it to imports above
  banner: Banner,
  information_card: InfoCard,
  content_block: ContentBlock,
  faq: Faq,
  video: VideoComp,
  tabbed_image_and_products: TabbedImageAndProducts,

  image_gallery: ImageGallery,
  image_content_alternating: AlternatingImages,
  category_cards: CategoryCards,
  dy_campaign: DYCampaign,
  yotpo_gallery: YotpoGallery,

  jotform_form: JotformEmbed,
  three_banner: TripleBanner,
  snackbar: Snackbar,
  topbanner: TopBanner,
  dy_recommendation_widget: DYRecWidget,
  cts_recommendation_widget: CTSRecWidget,
  guided_selling_carousel: GuidedSellingCarousel,

  post_carousel: PostCarousel,
  firestarters_banner: FirestartersBanner,
  info_boxes: InfoBoxes,
  quote_carousel: QuoteCarousel,
  value_prop: ValueProp,

  comparison_table: ComparisonTable,
  scroll_to_nav: ScrollToNav,
  gleam_raffle: GleamRaffle,

  hlp_hero_module: HlpHeroModule,
  hlp_category_module: HlpCategoryModule,
  hlp_product_category_module: HlpProductAndCategoryGridModule,
  hlp_ticker_module: HlpTickerModule,
  hlp_image_module: HlpImageModule,
  hlp_video_module: HlpVideoModule,
};

const props = defineProps({
  sectionEntry: {
    type: (Object as () => any) || (Array as () => any[]) || String || undefined,
    required: false,
    default: undefined,
  },
  sectionKey: {
    type: String || undefined,
    required: false,
    default: undefined,
  },
});

const id = props.sectionKey === 'product_highlight' ? generateIdNoDate('highlight') : undefined;
const isProductHighlight = props.sectionKey === 'product_highlight';
const componentProps = computed<{ [index: string]: any } | any | string | undefined>(() => {
  if (!props.sectionEntry) return undefined;
  const properties = Object.keys(props.sectionEntry);
  const sectionEntry = properties.length === 1 ? props.sectionEntry[properties[0]] : props.sectionEntry;
  /**
   *  component is either connection (edges or array of edges (product_highlight))
   *  or content (array[0]) or top-level with multiple properties
   */
  let component = sectionEntry;
  component = has(component, 'edges[0].node')
    ? get(component, 'edges[0].node')
    : isArray(component)
    ? component[0]
    : component;
  if (props.sectionKey === 'scroll_to_nav') {
    return {
      quality: connectionStore.quality,
      links: sectionEntry,
    };
  }
  if (
    props.sectionKey === 'product_highlight' ||
    props.sectionKey === 'cts_recommendation_widget' ||
    props.sectionKey === 'guided_selling_carousel'
  ) {
    // UPDATE LOGIC FOR NEW MODEL
    if (component?.products?.length) {
      const mappedItems = component?.products?.map((item: any) => {
        const productConnection = extractComponent(item?.productConnection);
        // Map props for ProductCard
        return {
          quality: connectionStore.quality,
          product: productConnection,
          yotpoKey,
          isDynamicSection: true,
          ...component,
          defaultVariantSku: item?.default_sku,
        };
      });
      if (props.sectionKey === 'cts_recommendation_widget') {
        return { recs: mappedItems };
      }
      if (props.sectionKey === 'guided_selling_carousel') {
        return { products: mappedItems, renderMode: component.renderMode, headingText: component.heading_text };
      }
      return mappedItems;
    } else if (component?.productConnection?.edges?.length) {
      const mappedItems = component?.productConnection?.edges?.map((item: any) => {
        // Map props for ProductCard
        const node = item?.node;
        return {
          quality: connectionStore.quality,
          product: {
            ...node,
          },
          rowSize: component?.productConnection?.edges.length,
          yotpoKey,
          isDynamicSection: true,
          ...component,
        };
      });
      if (props.sectionKey === 'guided_selling_carousel') {
        return { products: mappedItems, renderMode: component.renderMode, headingText: component.heading_text };
      }
      return mappedItems;
    }
  } else if (props.sectionKey === 'content_block') {
    // for content block right now
    return {
      content: getFirstIndex(props.sectionEntry?.content as any[]),
    };
  } else if (props.sectionKey === 'dy_recommendation_widget') {
    return {
      campaign_id: props.sectionEntry?.campaign_id,
      cartModal: inject<boolean>('isCartModal', false),
    };
  } else if (props.sectionKey === 'Yotpo_Gallery') {
    // for content block right now
    return {
      gallery_id: props.sectionEntry?.gallery_id as string,
      galleryId: props.sectionEntry?.gallery_id as string,
      yotpoKey,
    };
  } else if (props.sectionKey === 'jotform_form') {
    return {
      formId: props.sectionEntry?.form as string,
      subdomain: config?.jotformSubdomain,
    };
  } else if (props.sectionKey === 'topbanner') {
    return {
      topBanner: props.sectionEntry,
    };
  } else if (props.sectionKey === 'gleam_raffle') {
    return {
      raffleLink: props.sectionEntry.raffle_link,
    };
  } else if (component?.system?.content_type_uid === 'video') {
    return {
      quality: connectionStore.quality,
      options: { width: deviceStore.isMobile ? 390 : deviceStore.isDesktop ? 1184 : 768 },
      ...component,
    };
  }
  return {
    quality: connectionStore.quality,
    ...component,
  };
});
const componentName = computed<string | undefined>(() => {
  // map CTS Name to comp-lib/comp directory component names - use lowercase for key
  if (props.sectionKey === 'Content_Reference') {
    return compsMapper[componentProps.value?.system?.content_type_uid?.toLowerCase()];
  }
  if (props.sectionKey === 'product_highlight') {
    return ProductCard;
  }
  // Non "content-reference components"
  // return this.sectionKey ? resolveComponent(compsMapper[this.sectionKey.toLowerCase()]) : undefined;
  return props.sectionKey ? compsMapper[props.sectionKey.toLowerCase()] : undefined;
});
const hasMultipleItems = computed<boolean>(() => !!componentProps.value?.length);
const buttonLink = computed<string>(() => props.sectionEntry?.button_link?.href);
const displayComponentButton = computed<boolean>(() => {
  return props.sectionEntry?.display_buttons && props.sectionEntry?.primary_button_text && buttonLink.value;
});

const componentClasses = computed<string[]>(() => {
  const classes: string[] = ['component-wrapper'];
  if (props.sectionKey === 'Content_Reference') {
    // return compsMapper[componentProps.value?.system?.content_type_uid?.toLowerCase()];
    const refEdges = get(componentProps.value, 'referenceConnection.edges') || [];
    refEdges.forEach((x: any) => {
      const uid = x?.node?.system?.content_type_uid;
      if (uid) classes.push(`component-wrapper--${kebabCase(uid)}`);
    });
  }
  classes.push(`component-wrapper--${kebabCase(props.sectionKey)}`);

  if (componentProps.value?.full_bleed_layout) {
    classes.push('component-wrapper-full-bleed');
  }

  return classes;
});

const evName = 'sectionViewed';

const sectionViewed = () => {
  const viewProducts = componentProps?.value?.map((component: any) => {
    const productOfferings = component?.product?.product_offerings;
    const variant = productOfferings?.product_variations?.[0]?.bc_variant;
    return {
      product: productOfferings?.bc_primary_product?.product,
      variant,
    };
  });
  gtm?.trackEvent(mapProductCardsItemsToGtmEvent(GtmEventType.viewItemList, viewProducts));
};

onMounted(() => {
  if (id) {
    triggerEventWhenSectionVisible(id, evName);
    document.getElementById(id)?.addEventListener(evName, sectionViewed);
  }
});
onUnmounted(() => {
  if (id) {
    document.getElementById(id)?.removeEventListener(evName, sectionViewed);
  }
});
</script>

<style lang="scss" scoped>
.component-wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(local-vars.$xxl-content-max-width - 4rem);
  &--topbanner {
    max-width: 100%;
  }
  &--product-highlight {
    @include local-mixins.tablet_and_mobile {
      .button-container {
        margin: 0 auto;
        max-width: #{local-functions.rem-calc(200)};
      }
    }
  }
}
.section-wrapper {
  &.section-wrapper--hlp_hero_module,
  &.section-wrapper--hlp_product_category_module,
  &.section-wrapper--hlp_category_module,
  &.section-wrapper--hlp_ticker_module,
  &.section-wrapper--hlp_image_module,
  &.section-wrapper--hlp_video_module {
    .component-wrapper {
      max-width: 100%;
      padding-left: 0;
    }
  }
}
</style>
